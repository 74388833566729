// PushNotificationUtils.js
import apiCaller from '../../Utils/apiCaller';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';

import {
  urlBase64ToUint8Array,
  arrayBufferToBase64,
} from '../../Utils/conversionUtils';

const apiBaseUrl = getApiBaseUrl();

export const registerPushSubscription = async (
  serviceWorker: ServiceWorkerRegistration,
  isAuthenticated: boolean
): Promise<void> => {
  if (!isAuthenticated) {
    return;
  }

  try {
    const convertedVapidKey = urlBase64ToUint8Array(
      process.env.REACT_APP_VAPID_PUBLIC_KEY || ''
    );

    const subscription = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    });

    const p256dh = subscription.getKey('p256dh');
    const auth = subscription.getKey('auth');
    const subscriptionObject = {
      endpoint: subscription.endpoint,
      keys: {
        p256dh: p256dh ? arrayBufferToBase64(p256dh) : '',
        auth: auth ? arrayBufferToBase64(auth) : '',
      },
    };

    await apiCaller({
      endpoint: `${apiBaseUrl}/api/subscribe`,
      method: 'POST',
      body: { subscription: subscriptionObject },
      includeToken: true,
    });
  } catch (error) {
    console.error('Error subscribing to push notifications:', error);
  }
};
