import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa';
import { Modal, Card, Box, RotatableIcon } from '../../../storyBook';
import { SnapNote } from '../../types';
import SubMenu from '../../Timeline/SubMenu';
import useSnapNote from '../../hooks/useSnapNote';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Text, Flex } from '../../../storyBook';
import { formatDate } from '../../Utils/helper';
import Loader from '../../_common/Loader';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import { GoStarFill } from 'react-icons/go';
import useTimeline from '../../hooks/useTimeline';
import { useLocation } from 'react-router-dom';

import {
  CardStyle,
  Label,
  ImageGallery,
  Image,
  LeftArrow,
  RightArrow,
  PreviewImage,
  RotableContainer,
  DownloadIcon,
  ImageContainer,
  ImageOverlay,
} from './style';
import theme from '../../../theme';

const SnapNoteCard = ({
  snapNote,
  toggleStar,
}: {
  snapNote: SnapNote;
  toggleStar: () => void;
}) => {
  const { deleteSnapNote, loading } = useSnapNote();
  const galleryRef = useRef<HTMLDivElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const apiBaseUrl = getApiBaseUrl();
  const { addSnapnoteToTimeline } = useTimeline();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const timelineId = query.get('timelineId');

  const history = useHistory();
  const path = useRouteMatch();

  const { label, content, images, dateCreated } = snapNote;

  if (loading) {
    return <Loader isLoading={true} />;
  }

  const updateArrowVisibility = () => {
    const gallery = galleryRef.current;
    if (gallery) {
      setShowLeftArrow(gallery.scrollLeft > 0);
      setShowRightArrow(
        gallery.scrollLeft < gallery.scrollWidth - gallery.clientWidth
      );
    }
  };

  useEffect(() => {
    const gallery = galleryRef.current;
    if (gallery) {
      updateArrowVisibility();
      const handleScroll = () => updateArrowVisibility();
      gallery.addEventListener('scroll', handleScroll);
      return () => gallery.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const gallery = galleryRef.current;
    if (gallery) {
      if (direction === 'left') {
        gallery.scrollBy({ left: -gallery.offsetWidth, behavior: 'smooth' });
      } else {
        gallery.scrollBy({ left: gallery.offsetWidth, behavior: 'smooth' });
      }
    }
  };

  const getImageUrl = (image: string) => {
    if (image.startsWith('http://') || image.startsWith('https://')) {
      return image;
    }
    return `${apiBaseUrl}/${image}`;
  };
  const handleImageClick = (image: string) => {
    setPreviewImage(getImageUrl(image));
  };
  const downloadImage = (image: string) => {
    const link = document.createElement('a');
    link.href = getImageUrl(image);
    link.setAttribute('download', label || 'downloadedImage.jpg'); // Use label as the filename, default to 'downloadedImage.jpg'
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  const handleDelete = async () => {
    if (snapNote._id) {
      await deleteSnapNote(snapNote._id);
    }
  };

  const handleEdit = () => {
    history.push(`${path.url}/edit/${snapNote._id}`);
  };

  return (
    <CardStyle>
      <RotableContainer>
        <RotatableIcon
          _id={snapNote._id || ''}
          onToggle={handleMenuToggle}
          isOpen={openMenuId === snapNote._id}
        />
        {openMenuId === snapNote._id && (
          <SubMenu
            id={snapNote._id}
            timelineId={timelineId || ''}
            showEditOption={true}
            snapNote
            onEdit={handleEdit}
            onDelete={handleDelete}
            setOpenMenuId={setOpenMenuId}
            addToTimeline
            addSnapnoteToTimeline={addSnapnoteToTimeline}
          />
        )}
      </RotableContainer>

      <Label>
        <Flex flexDirection="column">
          <Text as="small">{formatDate(dateCreated)}</Text>
          <Flex alignItems="center" justifyContent="space-between" mr={1}>
            {label}

            <GoStarFill
              size={20}
              color={
                snapNote.isStarred ? theme.colors.pending : theme.colors.medium
              }
              style={{ cursor: 'pointer' }}
              onClick={toggleStar}
            />
          </Flex>
        </Flex>
      </Label>
      {content && (
        <Box mb={3}>
          <Card background={theme.colors.ultraLight} padding={theme.spacing.sm}>
            {content}
          </Card>
        </Box>
      )}

      {showLeftArrow && (
        <LeftArrow onClick={() => scroll('left')}>
          <FaArrowLeft />
        </LeftArrow>
      )}
      <ImageGallery ref={galleryRef}>
        {images?.map((image, index) => (
          <ImageContainer key={index}>
            <Image
              src={getImageUrl(image)}
              alt={`SnapNote Image ${index + 1}`}
              onClick={() => handleImageClick(image)}
            />
            <ImageOverlay>
              <DownloadIcon onClick={() => downloadImage(image)} />
            </ImageOverlay>
          </ImageContainer>
        ))}
      </ImageGallery>

      {showRightArrow && (
        <RightArrow onClick={() => scroll('right')}>
          <FaArrowRight />
        </RightArrow>
      )}

      <Modal onClose={closePreview} isOpen={!!previewImage}>
        {previewImage && <PreviewImage src={previewImage} alt="Preview" />}
      </Modal>
    </CardStyle>
  );
};

export default SnapNoteCard;
