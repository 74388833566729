import { ThemeProvider } from 'styled-components';
import theme from './theme';
import GlobalStyle from './theme/global';
import { AutoRefreshingRouter as Router } from './component/AutoRefreshingRouter';
import Routes from './component/routes';
import Layout from './component/Layout';
import CombinedProvider from './component/Context';
import NotificationRing from './component/Notifications/NotificationRing';
import ServiceWorkerManager from './ServiceWorkerManagement';

const App = () => {
  return (
    <CombinedProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <NotificationRing />
        <ServiceWorkerManager />
        <Router>
          <Layout>
            <Routes />
          </Layout>
        </Router>
      </ThemeProvider>
    </CombinedProvider>
  );
};

export default App;
