import { useState, useRef } from 'react';
import { Box, Text, Card, Flex, FloatingButton } from '../../storyBook';
import theme from '../../theme';
import { Hr } from '../_common/style';
import { ListContents, TextStyled } from './styles';
import { TbChevronRightPipe } from 'react-icons/tb';
import { TiArrowUpThick } from 'react-icons/ti';

const Documents = () => {
  const featuresRef = useRef(null);
  const benefitsRef = useRef(null);
  const useCasesRef = useRef(null);
  const securityRef = useRef(null);
  const demosRef = useRef(null);
  const dashboardRef = useRef(null);
  const addTimelinesRef = useRef(null);
  const addTimelinesCategoryRef = useRef(null);
  const editTimelinesRef = useRef(null);
  const statusUpdateRef = useRef(null);
  const searchTimelinesRef = useRef(null);
  const addSnapNotesRef = useRef(null);
  const editSnapNotesRef = useRef(null);
  const searchSnapNotesRef = useRef(null);
  const snapNoteCategoryRef = useRef(null);
  const addPasswordsRef = useRef(null);
  const editPasswordsRef = useRef(null);
  const searchPasswordsRef = useRef(null);
  const setMasterPasswordRef = useRef(null);
  const notificationsRef = useRef(null);
  const feedbackRef = useRef(null);

  const [showButton, setShowButton] = useState(false);
  const [visitedSections, setVisitedSections] = useState<
    React.RefObject<HTMLElement>[]
  >([]);
  const topRef = useRef(null);

  // Function to scroll to the top of the page
  const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 65,
        behavior: 'smooth',
      });
      if (!visitedSections.includes(ref)) {
        setVisitedSections([...visitedSections, ref]);
      }
      setShowButton(true);
    }
  };

  // Function to scroll to the top of the page
  const scrollToTop = (topRef: React.RefObject<HTMLElement>) => {
    if (topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop - 65,
        behavior: 'smooth',
      });
    }
    setShowButton(false);
  };

  return (
    <>
      <Box ref={topRef} color={theme.colors.offBlack} p={2}>
        <Text as="h2" mb={2} color={theme.colors.offBlack}>
          Live Diary
        </Text>
        <Hr />
        <Text bold as="h3" mb={2} mt={3}>
          Overview
        </Text>
        <Card p={2} mb={3} background={theme.colors.primaryLight}>
          <Flex>
            <Box mr={4}>
              <TbChevronRightPipe
                size={30}
                color={theme.colors.supportingBlue}
              />
            </Box>
            <Text>
              <Text bold as="span">
                {' '}
                Live Diary{' '}
              </Text>{' '}
              is a universal application designed to enhance the organization
              and security of personal data through its integrated modules:
              Timelines, SnapNotes, and Passwords.
            </Text>
          </Flex>
        </Card>
        <TextStyled>
          This comprehensive tool helps users manage their time, capture
          important information, and secure sensitive data all in one platform.
          Whether it&apos;s tracking significant life events, storing multimedia
          notes, or managing login credentials,{' '}
          <Text bold as="span">
            {' '}
            Live Diary{' '}
          </Text>{' '}
          offers a streamlined solution for maintaining order and security in
          daily life. Its user-friendly interface and robust features make it an
          essential application for anyone looking to effectively manage their
          personal information across multiple dimensions.
        </TextStyled>
        <Text bold as="h3" mb={2} mt={3}>
          Table of Contents
        </Text>
        <Box pl={4}>
          <ListContents
            as="li"
            color={visitedSections.includes(featuresRef)}
            onClick={() => scrollToRef(featuresRef)}
          >
            Features
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(benefitsRef)}
            onClick={() => scrollToRef(benefitsRef)}
          >
            Benefits
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(useCasesRef)}
            onClick={() => scrollToRef(useCasesRef)}
          >
            Use Cases
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(securityRef)}
            onClick={() => scrollToRef(securityRef)}
          >
            Security
          </ListContents>
          <ListContents
            as="li"
            color={visitedSections.includes(demosRef)}
            onClick={() => scrollToRef(demosRef)}
          >
            Demos
          </ListContents>
          <ListContents as="ul" mt={1}>
            <ListContents
              as="li"
              color={visitedSections.includes(dashboardRef)}
              onClick={() => scrollToRef(dashboardRef)}
            >
              Dashboard
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addTimelinesRef)}
              onClick={() => scrollToRef(addTimelinesRef)}
            >
              Add Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addTimelinesCategoryRef)}
              onClick={() => scrollToRef(addTimelinesCategoryRef)}
            >
              Add Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(editTimelinesRef)}
              onClick={() => scrollToRef(editTimelinesRef)}
            >
              Edit and delete Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(statusUpdateRef)}
              onClick={() => scrollToRef(statusUpdateRef)}
            >
              Status update and calendar Timelines{' '}
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(searchTimelinesRef)}
              onClick={() => scrollToRef(searchTimelinesRef)}
            >
              Search and filter Timelines
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addSnapNotesRef)}
              onClick={() => scrollToRef(addSnapNotesRef)}
            >
              Write SnapNotes
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(editSnapNotesRef)}
              onClick={() => scrollToRef(editSnapNotesRef)}
            >
              Edit delete and view SnapNotes
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(searchSnapNotesRef)}
              onClick={() => scrollToRef(searchSnapNotesRef)}
            >
              Search and filter SnapNotes
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(snapNoteCategoryRef)}
              onClick={() => scrollToRef(snapNoteCategoryRef)}
            >
              SnapNotes category
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(addPasswordsRef)}
              onClick={() => scrollToRef(addPasswordsRef)}
            >
              Add Passwords
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(editPasswordsRef)}
              onClick={() => scrollToRef(editPasswordsRef)}
            >
              Edit delete and view Passwords
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(searchPasswordsRef)}
              onClick={() => scrollToRef(searchPasswordsRef)}
            >
              Search and filter Passwords
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(setMasterPasswordRef)}
              onClick={() => scrollToRef(setMasterPasswordRef)}
            >
              Set and Reset Master Password
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(notificationsRef)}
              onClick={() => scrollToRef(notificationsRef)}
            >
              Notifications
            </ListContents>
            <ListContents
              as="li"
              color={visitedSections.includes(feedbackRef)}
              onClick={() => scrollToRef(feedbackRef)}
            >
              Feedback
            </ListContents>
          </ListContents>
        </Box>
        <Box ref={featuresRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Features
          </Text>
          <Card p={2} mb={3} background={theme.colors.primaryLight}>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text mb={2}>
                <Text bold as="span">
                  {' '}
                  Live Diary{' '}
                </Text>{' '}
                offers a range of features to help users manage their personal
                information effectively. These features include:
              </Text>
            </Flex>
          </Card>
          <Text bold as="h3" mb={2} mt={3}>
            Timelines
          </Text>
          <TextStyled>
            The Timelines module in{' '}
            <Text bold as="span">
              {' '}
              Live Diary{' '}
            </Text>{' '}
            enables users to meticulously create and manage events or milestones
            in a sequential manner. This module offers comprehensive features
            for adding, editing, and deleting timelines, along with the
            capability to set reminders for impending events. Users can
            personalize their timelines with specific categories, detailed
            content, and exact dates and times, ensuring they stay on top of
            personal projects, appointments, and significant occasions.
            Additionally, the module incorporates a calendar view that visually
            represents these timelines, facilitating efficient planning and
            schedule management. To enhance timely notifications, users will
            receive three reminders for each event: one at 7 AM as a daily
            summary, another ten minutes before the event, and a final alert at
            the exact scheduled time. Each day&apos;s timeline is automatically
            updated to the dashboard for immediate visibility. Users also have
            the option to activate audible notifications, ensuring they never
            miss important updates. This functionality not only simplifies time
            management but also enriches the user&apos;s planning accuracy and
            punctuality.
          </TextStyled>
          <Box ref={addTimelinesRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Add Timelines
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/add-timelines.gif"
              alt="add-timeline"
            />
          </Box>
          <Box ref={addTimelinesCategoryRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Add and delete timelines category
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/add-and-delete-category.gif"
              alt="add-timeline-category"
            />
          </Box>

          <Box ref={editTimelinesRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Edit and delete Timelines
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/edit-and-delete-timelines.gif"
              alt="edit-timeline"
            />
          </Box>
          <Box ref={statusUpdateRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Status update and calendar Timelines
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/status-and-calendar-timelines.gif"
              alt="status-timeline"
            />
          </Box>
          <Box ref={searchTimelinesRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Search and filter Timelines
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/search-and-filter-timelines.gif"
              alt="search-timeline"
            />
          </Box>
          <Text bold as="h3" mb={2} mt={3}>
            SnapNotes
          </Text>
          <TextStyled>
            SnapNotes is tailored for users who need to capture and organize
            information quickly and efficiently. This module enables the
            creation of notes with multimedia attachments, such as images
            captured directly from the device&apos;s camera or uploaded from
            storage. Users can label and categorize their notes for easy
            retrieval. SnapNotes is particularly useful for capturing details
            on-the-go, storing everything from quick reminders to detailed
            documentation of important activities or events, making it an
            invaluable tool for both personal and professional use.
          </TextStyled>
          <Box ref={addSnapNotesRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Write SnapNotes
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/write-snapnote.gif"
              alt="write-snapnote"
            />
          </Box>
          <Box ref={editSnapNotesRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Edit and delete SnapNotes
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/edit-and-delete-snapnote.gif"
              alt="edit-snapnote"
            />
          </Box>
          <Box ref={searchSnapNotesRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Search and filter SnapNotes
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/search-and-filter-snapnote.gif"
              alt="search-snapnote"
            />
          </Box>
          <Box ref={snapNoteCategoryRef}>
            <Text bold as="h4" mb={2} mt={3}>
              SnapNotes category
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/snapnote-category.gif"
              alt="snapnote-category"
            />
          </Box>
          <Text bold as="h3" mb={2} mt={3}>
            Passwords
          </Text>
          <TextStyled>
            The Passwords module is dedicated to secure storage and management
            of passwords and sensitive login details. It incorporates robust
            security features such as encryption and the ability to set and
            reset a master password, which protects all stored data. Users can
            easily add, edit, or delete password entries. This module also
            supports the secure retrieval of passwords, ensuring that users can
            access their credentials whenever necessary while maintaining high
            security standards to prevent unauthorized access.
          </TextStyled>
          <Box ref={addPasswordsRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Add Passwords
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/add-password.gif"
              alt="add-password"
            />
          </Box>
          <Box ref={editPasswordsRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Edit and delete Passwords
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/edit-delete-password.gif"
              alt="edit-password"
            />
          </Box>
          <Box ref={searchPasswordsRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Search Passwords
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/search-password.gif"
              alt="search-password"
            />
          </Box>
          <Box ref={setMasterPasswordRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Set and Reset Master Password
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/set-reset-password.gif"
              alt="set-reset-password"
            />
          </Box>
        </Box>

        <Box ref={benefitsRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Benefits
          </Text>
          <Card p={3} mb={3} background={theme.colors.primaryLight}>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                <Text as="span">
                  <Text bold as="span">
                    {' '}
                    Live Diary{' '}
                  </Text>
                </Text>{' '}
                brings a multitude of benefits to its users by integrating
                functionalities across Timelines, SnapNotes, and Passwords into
                one streamlined platform:
              </Text>
            </Flex>
          </Card>

          <Text as="ul" mt={1}>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Enhanced Organization:
              </Text>{' '}
              The application allows users to meticulously plan and track
              personal projects, appointments, and significant events with ease,
              enhancing their daily productivity and organization.
            </Text>

            <Text as="li" mb={1}>
              <Text bold as="span">
                Data Security:
              </Text>{' '}
              With robust encryption and security measures in place, users can
              store and manage sensitive information such as passwords and
              multimedia notes without compromising their privacy or security.
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Time Management:
              </Text>{' '}
              Timely notifications and reminders for upcoming events ensure that
              users stay on schedule. The application&apos;s automated features
              like adding timelines to the dashboard daily increase efficiency.
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Accessibility:
              </Text>{' '}
              The platform&apos;s user-friendly interface ensures that managing
              personal data, from notes to passwords, is accessible to all
              users, regardless of their technical skills.
            </Text>
          </Text>
        </Box>

        <Box ref={useCasesRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Use Cases
          </Text>
          <Card p={3} mb={3} background={theme.colors.primaryLight}>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                <Text bold as="span">
                  {' '}
                  Live Diary{' '}
                </Text>{' '}
                is designed to cater to a wide range of user needs and
                scenarios. Some common use cases include:
              </Text>
            </Flex>
          </Card>

          <Text as="ul" mt={1}>
            <Text as="li" mb={1}>
              <Text bold as="span">
                Personal organization:{' '}
              </Text>{' '}
              Users can create timelines to track personal events, set
              reminders, and manage their schedules
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Professional planning:{' '}
              </Text>{' '}
              Business professionals can use SnapNotes to capture meeting notes,
              store important documents, and manage project details
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Password management:{' '}
              </Text>{' '}
              Individuals can securely store and retrieve login credentials,
              ensuring easy access to online accounts
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Educational purposes:{' '}
              </Text>{' '}
              Students can use{' '}
              <Text bold as="span">
                {' '}
                Live Diary{' '}
              </Text>{' '}
              to organize study schedules, set assignment deadlines, and store
              course materials
            </Text>
            <Text as="li" mb={1}>
              <Text bold as="span">
                {' '}
                Creative projects:{' '}
              </Text>{' '}
              Artists, writers, and designers can utilize SnapNotes to capture
              ideas, inspiration, and visual references
            </Text>
          </Text>
        </Box>
        <Box ref={securityRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Security
          </Text>
          <Card p={3} mb={3} background={theme.colors.primaryLight}>
            <Flex>
              <Box mr={4}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                Security is a cornerstone of the{' '}
                <Text bold as="span">
                  {' '}
                  Live Diary{' '}
                </Text>{' '}
                application, with several layers protecting the integrity and
                confidentiality of user data:
              </Text>
            </Flex>
          </Card>

          <Text as="ul" mt={1}>
            <Text as="li">
              <Text bold as="span">
                Encryption:
              </Text>{' '}
              All password data is encrypted using advanced algorithms, ensuring
              that sensitive information is always protected from unauthorized
              access.
            </Text>
            <Text as="li">
              <Text bold as="span">
                Master Passwords:
              </Text>{' '}
              The ability to set and reset master passwords adds an additional
              level of security, safeguarding all stored passwords under a
              single, secure key.
            </Text>
            <Text as="li">
              <Text bold as="span">
                Secure Access:
              </Text>{' '}
              Users receive notifications and alerts that not only keep them
              updated but also ensure that any access to the application&apos;s
              comprehensive features is monitored and recorded for security.
            </Text>
          </Text>
          <TextStyled>
            Through these attributes,{' '}
            <Text bold as="span">
              {' '}
              Live Diary{' '}
            </Text>{' '}
            not only supports users in their daily organizational tasks but also
            ensures that their information remains secure and private, providing
            a reliable and efficient way to manage personal data.
          </TextStyled>
        </Box>
        <Box ref={demosRef}>
          <Text bold as="h3" mb={2} mt={3}>
            Demos
          </Text>

          <Card p={3} mb={3} background={theme.colors.primaryLight}>
            <Flex>
              <Box mr={2}>
                <TbChevronRightPipe
                  size={30}
                  color={theme.colors.supportingBlue}
                />
              </Box>
              <Text>
                This section demonstrates the key features of{' '}
                <Text bold as="span">
                  {' '}
                  Live Diary&apos;s
                </Text>
                .
              </Text>
            </Flex>
          </Card>
          <Box ref={dashboardRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Dashboard View
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/dashboard.gif"
              alt="dashboard"
            />
          </Box>
          <Box ref={notificationsRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Notifications
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/notification.gif"
              alt="notification"
            />
          </Box>
          <Box ref={feedbackRef}>
            <Text bold as="h4" mb={2} mt={3}>
              Feedback
            </Text>
            <img
              src="https://storage.cloud.google.com/live-diary-gif/feedback.gif"
              alt="feedback"
            />
          </Box>
        </Box>
        {showButton && (
          <FloatingButton
            icon={<TiArrowUpThick />}
            onClick={() => scrollToTop(topRef)}
          />
        )}
      </Box>
    </>
  );
};

export default Documents;
