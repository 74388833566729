import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { notify } from '../../../storyBook/TostNotification';
import useAuth from '../../hooks/useAuth';

// Define a type for the notification object
export type Notification = {
  message: string;
  timestamp: string;
  data: {
    isActualReminder: boolean;
    ring: boolean;
    _id: string;
    reminderDate: string;
    reminderTime: string;
    status: string;
    category: string;
    content: string;
  };
  read: boolean;
};

// Define a type for the context value
export type NotificationContextType = {
  notifications: Notification[];
  ringAlarmNotification: Notification | null;
  setRingAlarmNotification: (value: Notification | null) => void;
  loading: boolean;
  addNotification: (notification: Notification) => void;
  markAllNotificationsAsRead: () => void;
  deleteNotification: (_id: string) => void;
  clearNotifications: () => void;
  isRingNotificationActive: boolean;
  setIsRingNotificationActive: (value: boolean) => void;
};

// Create the context with a default value
export const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  ringAlarmNotification: null,
  setRingAlarmNotification: () => {},
  loading: false,
  addNotification: () => {},
  markAllNotificationsAsRead: () => {},
  deleteNotification: () => {},
  clearNotifications: () => {},
  isRingNotificationActive: false,
  setIsRingNotificationActive: () => {},
});

// Custom hook to use the notification context
export const useNotifications = () => useContext(NotificationContext);

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const fallbackUserDetails = JSON.parse(
    localStorage.getItem('userDetails') || '{}'
  );
  const userId = fallbackUserDetails?._id;
  const userSpecificKey = `notifications-${userId}`;
  const [loading, setLoading] = useState<boolean>(false);
  const [isRingNotificationActive, setIsRingNotificationActive] =
    useState(false);
  const [ringAlarmNotification, setRingAlarmNotification] =
    useState<Notification | null>(null);
  const { isAuthenticated } = useAuth();

  const [notifications, setNotifications] = useState<Notification[]>(() => {
    // Load cached notifications from local storage using user-specific key
    const savedNotifications = localStorage.getItem(userSpecificKey);
    return savedNotifications ? JSON.parse(savedNotifications) : [];
  });

  // Add a useEffect hook to refresh notifications on user login
  useEffect(() => {
    if (isAuthenticated && userId) {
      setLoading(true);
      const savedNotifications = localStorage.getItem(userSpecificKey);
      setNotifications(
        savedNotifications ? JSON.parse(savedNotifications) : []
      );
      setLoading(false);
    }
  }, [isAuthenticated, userId]);

  const markAllNotificationsAsRead = useCallback(() => {
    setNotifications((prevNotifications) => {
      const updatedNotifications = prevNotifications.map((notification) => ({
        ...notification,
        read: true,
      }));
      localStorage.setItem(
        userSpecificKey,
        JSON.stringify(updatedNotifications)
      );

      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'updateBadge',
          count: 0,
        });
      }

      return updatedNotifications;
    });
  }, [userSpecificKey]);

  const deleteNotification = useCallback(
    (_id: string) => {
      setNotifications((prevNotifications) => {
        const updatedNotifications = prevNotifications.filter(
          (notification) => notification.data._id !== _id
        );
        localStorage.setItem(
          userSpecificKey,
          JSON.stringify(updatedNotifications)
        );
        return updatedNotifications;
      });
    },
    [userSpecificKey]
  );

  const clearNotifications = useCallback(() => {
    setNotifications([]);
    localStorage.removeItem(userSpecificKey);
  }, [userSpecificKey]);

  const addNotification = (notification: Notification) => {
    if (notification.data.ring) {
      setIsRingNotificationActive(true);
      setRingAlarmNotification(notification);
    }
    setNotifications((prevNotifications) => {
      let updatedNotifications = [
        ...prevNotifications,
        { ...notification, read: false },
      ];

      // Keep only the latest 100 notifications
      if (updatedNotifications.length > 100) {
        updatedNotifications = updatedNotifications.slice(0, 100);
      }

      localStorage.setItem(
        userSpecificKey,
        JSON.stringify(updatedNotifications)
      );

      // Trigger toast notification
      notify({
        message: notification.message,
        type: 'success',
      });

      return updatedNotifications;
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        loading,
        notifications,
        addNotification,
        ringAlarmNotification,
        markAllNotificationsAsRead,
        deleteNotification,
        clearNotifications,
        isRingNotificationActive,
        setRingAlarmNotification,
        setIsRingNotificationActive,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
