// CategoryContext.tsx
import { createContext, useState, useEffect } from 'react';
import apiCaller from '../../Utils/apiCaller';
import { handleError } from '../../Utils/helper';
import { notify } from '../../../storyBook/TostNotification';
import getApiBaseUrl from '../../Utils/GetApiBaseUrl';
import useAuth from '../../hooks/useAuth';
import { Category, CategoryContextType } from './types';

export const CategoryContext = createContext<CategoryContextType | null>(null);

export const CategoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const apiBaseUrl = getApiBaseUrl();

  // Fetch all categories
  const fetchCategories = async () => {
    if (!isAuthenticated) return;

    setLoading(true);
    setError(null);
    try {
      const response = await apiCaller<Category[]>({
        endpoint: `${apiBaseUrl}/api/categories`,
        method: 'GET',
        includeToken: true,
      });
      const formattedCategories = response.map((name) => ({
        name: name.toString(), // Convert the category name to a string
      }));
      setCategories(formattedCategories);
    } catch (err) {
      handleError(err, setError);
      notify({ message: 'Failed to fetch categories', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Add a new category
  const addCategory = async (name: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/add-category`,
        method: 'POST',
        body: { name },
        includeToken: true,
      });
      await fetchCategories();
    } catch (err) {
      notify({ message: 'Failed to add category', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };
  // Add a new function in the CategoryProvider component
  const deleteCategory = async (name: string) => {
    setLoading(true);
    setError(null);
    try {
      await apiCaller({
        endpoint: `${apiBaseUrl}/api/delete-category/${encodeURIComponent(
          name
        )}`,
        method: 'DELETE',
        includeToken: true,
      });
      notify({ message: 'Category deleted successfully', type: 'success' });
      await fetchCategories(); // Refresh categories after deletion
    } catch (err) {
      notify({ message: 'Failed to delete category', type: 'error' });
      handleError(err, setError);
    } finally {
      setLoading(false);
    }
  };

  // Fetch categories on initial render or when auth state changes
  useEffect(() => {
    fetchCategories();
  }, [isAuthenticated]);

  return (
    <CategoryContext.Provider
      value={{
        categories,
        fetchCategories,
        addCategory,
        deleteCategory,
        loading,
        error,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
