import { RxCross2 as Icon } from 'react-icons/rx';
import { BaseModal, Content, IconWrapper, Overlay, Wrap } from './style';

import { ModalProps } from './types';

const MODAL_STYLE = {
  overlay: {
    zIndex: 9999,
  },
};

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  fullPage = false,
  closeIcon = true,
  iconColor = 'light',
  background = null,
  noBackground = false,
  onClose,
  ...modalProps
}: ModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      style={MODAL_STYLE}
      onRequestClose={onClose}
      {...modalProps}
    >
      <Overlay onClick={onClose} />
      <Wrap
        fullPage={fullPage}
        background={background}
        noBackground={noBackground}
      >
        {closeIcon && (
          <IconWrapper aria-label="close-button" onClick={onClose}>
            <Icon width="18px" height="18px" color={iconColor} type="cross" />
          </IconWrapper>
        )}
        <Content>{children}</Content>
      </Wrap>
    </BaseModal>
  );
};

export default Modal;
