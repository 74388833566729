import { useEffect } from 'react';
import { registerPushSubscription } from '../src/component/Utils/pushNotificationUtils';
import useAuth from './component/hooks/useAuth';

const ServiceWorkerManager = () => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log(
            'Service Worker registered with scope:',
            registration.scope
          );
          if (registration.active) {
            registerPushSubscription(registration, isAuthenticated);
          } else {
            console.log('Service worker is not active yet.');
            registration.addEventListener('updatefound', () => {
              const newWorker = registration.installing;
              newWorker?.addEventListener('statechange', () => {
                if (newWorker.state === 'activated') {
                  registerPushSubscription(registration, isAuthenticated);
                }
              });
            });
          }
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    }
  }, [isAuthenticated]);

  return null; // This component does not render anything
};

export default ServiceWorkerManager;
